import React from "react"

import PropTypes from "prop-types"
import { Link } from "gatsby"

import { isInternalUrl } from "../../../../helpers/helpers"

import "./footer-menu.scss"

export const FooterMenu = ({ links }) => (
  <ul className="nav footer-menu flex-column m-b-2">
    {links.map(({ url, label, onClick, isActive, className }) => (
      <li className="nav-item" key={label}>
        {React.createElement(
          !isInternalUrl(url) || onClick ? "a" : Link,
          {
            className: `d-sm-flex footer-menu-link px-0 pt-0 pb-0 mb-1 ${className} ${
              isActive ? "is-active" : ""
            }`,
            href: url || "",
            to: url || "",
            onClick,
          },
          label
        )}
      </li>
    ))}
  </ul>
)

FooterMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      onClick: PropTypes.func,
      label: PropTypes.string,
      className: PropTypes.string,
      isActive: PropTypes.bool,
    })
  ),
}
