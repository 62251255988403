import React from "react"

import PropTypes from "prop-types"

export const HeaderSearch = ({ classNames }) => (
  <form
    aria-label="search"
    role="search"
    method="PUT"
    action="/search/"
    className={`header-search form-inline ${classNames}`}
  >
    <label htmlFor="query" className="sr-only">
      Search our resources
    </label>

    <div className="header-search-input d-flex input-group">
      <input
        required
        id="query"
        name="query"
        type="search"
        aria-label="Search"
        placeholder="Your query..."
        className="header-search-control form-control"
      />
      <div className="input-group-append">
        <button className="btn btn-primary" type="submit">
          Search
        </button>
      </div>
    </div>
  </form>
)

HeaderSearch.propTypes = {
  classNames: PropTypes.string,
}
