import React from "react"

import { Link } from "gatsby"
import { PropTypes } from "prop-types"
import { inject, observer } from "mobx-react"

import { isCurrentPage, isInternalUrl } from "../../../../../helpers/helpers"

import "./header-nav-link.scss"

export const HeaderNavLink = inject("headerStore")(
  observer(({ headerStore, href, label }) =>
    React.createElement(
      isInternalUrl(href) ? Link : "a",
      {
        className: `header-nav-link nav-link  ${
          isCurrentPage(href) ? "is-active" : ""
        }`,
        tabIndex:
          headerStore.isNavToggled || headerStore.isNavToggled === null
            ? 0
            : -1,
        href: href,
        to: href,
      },
      <>
        {label}
        {isCurrentPage(href) && (
          <span className="sr-only">&nbsp;(current)</span>
        )}
      </>
    )
  )
)

HeaderNavLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
}

HeaderNavLink.defaultProps = {
  href: "#",
}
