import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

export function SEO({ description, lang, meta, title, canonical }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width`,
        },
      ].concat(meta)}
    >
      {canonical && <link rel="canonical" href={canonical} />}

      <meta name="format-detection" content="telephone=no" />

      {process.env.IS_PRODUCTION === "true" && (
        <script
          type="text/javascript"
          defer
          src="https://webchat.nationaldebtline.org/WebChat/clientchat/InisoftWebChat?id=3"
        />
      )}
      {process.env.IS_PRODUCTION === "false" && (
        <script
          type="text/javascript"
          defer
          src="https://webchat-uat.nationaldebtline.org/WebChatMoneyAdviceTrustTest/clientchat/InisoftWebChat?id=3"
        />
      )}
      <script type="text/javascript" src="/survey-monkey.js" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
}
