import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import { HeaderNavLink } from "./header-nav-link/header-nav-link"
import { HeaderSearch } from "../header-search/header-search"

import "./header-nav.scss"

export const HeaderNav = ({ className, passedRef }) => {
  const { wagtail } = useStaticQuery(
    graphql`
      query {
        wagtail {
          steps: pages {
            ... on SimpleStepsRootPage {
              url
              title
            }
          }
          sampleLetters: pages {
            ... on SampleLetterLibraryPage {
              url
              title
            }
          }
          factSheets: pages {
            ... on FactSheetLibraryPage {
              url
              title
            }
          }
        }
      }
    `
  )

  const { steps, sampleLetters, factSheets } = wagtail

  const createLinksFromPageArray = pages =>
    pages.map(({ title, url }) => {
      if (title) {
        return (
          <li key={title} className="header-navbar-item nav-item">
            <HeaderNavLink href={url} label={title} />
          </li>
        )
      }
    })

  return (
    <div
      className={`header-navbar navbar-collapse ${className}`}
      ref={passedRef}
    >
      <HeaderSearch classNames="d-flex d-sm-none mb-2 mt-2" />

      <ul className="header-nav navbar-nav mr-auto">
        {createLinksFromPageArray(steps)}

        <li className="header-navbar-item nav-item">
          <HeaderNavLink
            href="https://tools.businessdebtline.org/yourbudget/"
            label="Your budget"
          />
        </li>

        {createLinksFromPageArray(factSheets)}
        {createLinksFromPageArray(sampleLetters)}
        <li className="header-navbar-item nav-item">
          <HeaderNavLink
            href="https://www.businessdebtline.org/cost-living-hub-ew/"
            label="Cost of living"
          />
        </li>
      </ul>
    </div>
  )
}

HeaderNav.propTypes = {
  className: PropTypes.string,
  passedRef: PropTypes.any,
}
