import React, { useEffect, useRef } from "react"

import PropTypes from "prop-types"
import { inject, observer } from "mobx-react"

import "./region-toggle.scss"

export const RegionToggle = inject("regionStore")(
  observer(({ className, forceRefresh, regionStore }) => {
    const { currentRegionData, alternateRegionData } = regionStore

    const label = currentRegionData.label

    // Note: there is an issue with Mobx and class updates. Therefore,
    // had to use this "hack" to ensure that the correct classes are
    // applied when "Scotland" is set as the default location via localStorage.
    const active = useRef(null)
    const inactive = useRef(null)

    useEffect(() => {
      const isScotland = active.current.innerHTML.includes("Scotland")
      active.current.classList.remove(isScotland ? "england-wales" : "scotland")
      inactive.current.classList.remove(
        isScotland ? "scotland" : "england-wales"
      )
      active.current.classList.add(isScotland ? "scotland" : "england-wales")
      inactive.current.classList.add(isScotland ? "england-wales" : "scotland")
    }, [regionStore.currentRegion])

    const handleOnClick = () => {
      regionStore.switchCurrentRegion()
      
      let thisPageForcesRefresh = false;

      if(window.location.pathname.indexOf('/cost-living') == 0 || window.location.pathname.indexOf('/costs-living') == 0) {
        thisPageForcesRefresh = true;
      }

      if (forceRefresh || thisPageForcesRefresh) {
        location.reload()
      }
    }

    const handleOnKeyDown = ({ key }) => {
      if (key === "Enter") {
        regionStore.switchCurrentRegion()
      }
    }

    return (
      <div className={`region-toggle ${className}`}>
        <div className="region-toggle-current" ref={active}>
          <span className="sr-only">Currently selected region: </span>
          {label}
        </div>

        <span
          role="button"
          aria-live="polite"
          tabIndex={0}
          onKeyDown={handleOnKeyDown}
          onClick={handleOnClick}
          ref={inactive}
          className="region-toggle-change"
          aria-label={`Change region to ${alternateRegionData.label}`}
        >
          Change
        </span>
      </div>
    )
  })
)

RegionToggle.propTypes = {
  className: PropTypes.string,
  forceRefresh: PropTypes.bool,
}
