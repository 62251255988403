import React, { useRef, useEffect } from "react"

import { Link } from "gatsby"
import { inject, observer } from "mobx-react"

import { RegionToggle } from "../../modules/region-toggle/region-toggle"
import { HeaderNav } from "./header-nav/header-nav"
import { HeaderSearch } from "./header-search/header-search"

import Logo from "../../../images/logo.svg"

import "./header.scss"

export const Header = inject("headerStore")(
  observer(({ headerStore }) => {
    const nav = useRef(null)
    const menuToggle = useRef(null)

    useEffect(() => {
      headerStore.setIsNavToggled(null)
      window.addEventListener("resize", adjustMenuToggleState)
    }, [])

    const adjustMenuToggleState = () => {
      const { isNavToggled } = headerStore

      const isDesktopView = menuToggle.current
        ? window.getComputedStyle(menuToggle.current).display === "none"
        : true

      if (isDesktopView) {
        if (isNavToggled !== null) {
          headerStore.setIsNavToggled(null)
        }
      } else if (isNavToggled === null) {
        headerStore.setIsNavToggled(false)
      }
    }

    const toggleNavbar = () => {
      const { isNavToggled } = headerStore

      const isMenuOpening = isNavToggled === null ? true : !isNavToggled

      headerStore.setIsNavToggled(isMenuOpening)

      if (isMenuOpening) {
        nav.current.querySelector("input").focus()
      }
    }

    return (
      <header className="header mb-0 d-print-none">
        <nav
          className="container header-navbar navbar navbar-expand-lg pt-3 px-3 pb-2"
          aria-label="primary navigation"
        >
          <RegionToggle className="header-region-toggle no-radius-top" />

          <Link to="/" className="navbar-brand mb-1 p-0">
            <img src={Logo} className="header-logo" alt="Home" />
          </Link>

          <HeaderNav
            // Only displayed on desktop devices.
            className="desktop"
            passedRef={nav}
          />

          <div className="header-search-and-toggle d-flex">
            <HeaderSearch classNames="d-none d-sm-flex ml-1 mb-1 mb-lg-0 ml-lg-0" />

            <button
              ref={menuToggle}
              onClick={toggleNavbar}
              className="navbar-toggler header-nav-toggle mb-1 mb-lg-0"
              type="button"
              aria-expanded={headerStore.isNavToggled}
              aria-label="Toggle navigation"
            >
              <div
                className={`
                  header-nav-toggle-menu 
                  ${headerStore.isNavToggled ? "is-active" : ""}
                `}
                aria-hidden="true"
              >
                <div className="header-nav-toggle-menu-line line line-1" />
                <div className="header-nav-toggle-menu-line line line-2" />
                <div className="header-nav-toggle-menu-line line line-3" />
              </div>
            </button>
          </div>

          {/* <div className="d-flex header-telephone-text">
            <span aria-label="Telephone number">Freephone 0800 197 6026</span>
          </div> */}

          <HeaderNav
            // Only displayed on mobile devices.
            className={`
              mobile
                ${headerStore.isNavToggled ? "" : "is-hidden"} 
                ${headerStore.isNavToggled === null ? "no-animate" : ""}
              `}
            passedRef={nav}
          />
        </nav>
      </header>
    )
  })
)
