import React, { useState } from "react"

import axios from "axios"

import { Modal } from "../modal/modal"

import "./feedback-form.scss"

export const FeedbackForm = () => {
  const [isActive, setIsActive] = useState(false)

  const [hasSubmittedForm, setHasSubmittedForm] = useState(false)

  const openDialog = () => {
    setIsActive(true)
  }

  const closeDialog = () => {
    setIsActive(false)
    setTimeout(() => {
      setHasSubmittedForm(false)
    }, 175)
  }

  const handleOnFormSubmit = e => {
    e.preventDefault()
    setHasSubmittedForm(true)

    axios.post(process.env.EMAIL_SERVICE_URL, new FormData(e.target))
  }

  const buttons = [
    {
      variant: "primary",
      className: "chevron-icon pr-5",
      type: hasSubmittedForm ? "button" : "submit",
      label: hasSubmittedForm ? "Close form" : "Send feedback",
      ariaLabel: hasSubmittedForm ? "Close form" : "Send feedback",
      onClick: hasSubmittedForm ? closeDialog : undefined,
    },
  ]

  return (
    <>
      <button
        className="btn btn-dark w-auto d-print-none feedback-form-toggle"
        aria-label="Give website feedback"
        onClick={openDialog}
      >
        <p className="feedback-form-toggle-title">Feedback</p>
      </button>

      <Modal
        className="feedback-form-dialog overflow-scroll"
        onFormSubmit={handleOnFormSubmit}
        title={{ label: "Share your feedback" }}
        isActive={isActive}
        onClose={closeDialog}
        buttons={buttons}
      >
        {!hasSubmittedForm ? (
          <>
            <p>
              We’d love to know what you think of our website. Take our&nbsp;
              <a href="https://www.surveymonkey.co.uk/r/YTSK6ZF">
                short survey
              </a>
              &nbsp;to give us your feedback and help us improve our online
              services.
            </p>
            <p>
              If you are reporting a problem with using the site, please tell us
              in the space below. Please include which version of the internet
              browser you are using, for example Internet Explorer 8, as this
              may help us to fix things faster.
            </p>
            <p>
              We will only use any personal data you may disclose to understand
              your feedback and pass it on to the relevant department. You will
              not receive any marketing. If you are making a complaint and you
              provide contact details we will respond to you. For more details
              of our complaints policy please refer to our website.
            </p>
            <p>
              <strong>
                If you need a response, don’t forget to tell us your email
                address.
              </strong>
            </p>

            <input type="hidden" value="bdl-feedback" name="email-service" />

            <label htmlFor="email-address">
              <strong>Your email address</strong> (optional)
            </label>

            <input
              className="form-control mb-3"
              type="email"
              id="email-address"
              autoComplete="email"
              name="email-address"
              placeholder="jane.smith@example.com (optional)..."
            />

            <label htmlFor="email-body">
              <strong>Your feedback</strong> (required)
            </label>

            <textarea
              id="email-body"
              name="email-body"
              rows="3"
              className="form-control feedback-form-dialog-textarea mb-2 p-2"
              placeholder="Let us know your thoughts..."
              required
            />
          </>
        ) : (
          <>
            <p>
              <strong>Thank you for your feedback!</strong>
            </p>

            <p>
              We have recieved your comments and will contact you shortly if you
              have provided your email address.
            </p>
          </>
        )}
      </Modal>
    </>
  )
}
