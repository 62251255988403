import React, { useEffect } from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react"

import { Footer } from "../../layout/footer/footer"
import { Header } from "../../layout/header/header"
import { FeedbackForm } from "../../modules/feedback-form/feedback-form"
import { triggerWebchat } from "../../../helpers/helpers"

import "./wrapper.scss"

export const Wrapper = inject("regionStore")(
  observer(({ regionStore, className, hasFeedbackForm, children }) => {
    const { site } = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    const injectPhoneNumberLinks = () => {
      // Use "#PHONE_NUMBER" to determine whether or not it is a phone number link.
      const documentLinks = Array.from(
        document.querySelectorAll("a")
      );

      documentLinks.forEach((link) => {
        const href = link.getAttribute("href");

        if (href && href.indexOf('#PHONE_NUMBER') != -1) {
          link.setAttribute('href', 'tel:08001976026');
        }
      });
    };

    useEffect(() => {
      const { pathname } = window.location
      if (pathname === "/scotland") {
        regionStore.changeRegion("S")
        setTimeout(() => {
          window.location.pathname = "/"
        }, 250)
      } else {
        if(window.location.search) {
          const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
          if(params.region == 'england-wales') {
            regionStore.changeRegion("EW")
          }
          if(params.region == 'scotland') {
            regionStore.changeRegion("S")
          }
        }
      }
    }, [])

    // To inject phone number links when there's a match with a specific URL hash
    useEffect(() => {
      injectPhoneNumberLinks();
    }, [site.siteMetadata.title]);

    return (
      <>
        <a className="skip-to-content-link" href="#main">
          Skip to content
        </a>

        <Header siteTitle={site.siteMetadata.title} />

        <main className={`container wrapper ${className}`} id="main">
          {hasFeedbackForm && <FeedbackForm />}
          {children}
        </main>

        <Footer />
      </>
    )
  })
)

Wrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasFeedbackForm: PropTypes.bool,
}

Wrapper.defaultProps = {
  hasFeedbackForm: false,
}
